var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"change-password-form",class:{
    'change-password-form-small': _vm.$cr.breakpoint.xs,
  }},[_c('v-form',{ref:"changePasswordModal"},[_c('v-card',{staticClass:"cr-modal"},[_c('v-card-title',[_c('h2',[_vm._v("Change Password")])]),_c('v-card-text',[_c('div',[_c('label',[_vm._v("New Password")]),_c('v-text-field',{attrs:{"rules":[
              _vm.isRequired(true, _vm.isNotEmpty, {
                req: 'New Password Is Required',
                error: 'New Password Is Required',
              }),
              (v) => !_vm.shouldValidatePassword || _vm.asyncPasswordResponse.data.valid || _vm.asyncPasswordResponse.data.message
            ],"flat":"","solo":"","type":"password","name":"New Password"},model:{value:(_vm.changePasswordFormData.password),callback:function ($$v) {_vm.$set(_vm.changePasswordFormData, "password", $$v)},expression:"changePasswordFormData.password"}}),_c('label',[_vm._v("Confirm Password")]),_c('v-text-field',{attrs:{"rules":[
              _vm.isRequired(true, _vm.isNotEmpty, {
                req: 'Passwords Must Match',
                error: 'Passwords Must Match',
              }),
              _vm.shouldMatch(_vm.changePasswordFormData, 'password'),
            ],"flat":"","solo":"","type":"password","name":"Confirm Password"},model:{value:(_vm.changePasswordFormData.confirmPassword),callback:function ($$v) {_vm.$set(_vm.changePasswordFormData, "confirmPassword", $$v)},expression:"changePasswordFormData.confirmPassword"}})],1)]),_c('v-card-actions',[_c('v-layout',{attrs:{"justify-end":""}},[_c('div',{staticClass:"button-cont"},[_c('div',{staticStyle:{"margin-right":"-1.5%"}},[_c('v-btn',{attrs:{"id":"close-modal-button","color":"grey darken","outline":""},on:{"click":_vm.close}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"id":"save-button","color":"primary"},on:{"click":_vm.save}},[_vm._v(" Change Password ")])],1)])])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }