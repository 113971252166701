<template>
  <div
    class="change-password-form"
    :class="{
      'change-password-form-small': $cr.breakpoint.xs,
    }"
  >
    <v-form ref="changePasswordModal">
      <v-card class="cr-modal">
        <v-card-title>
          <h2>Change Password</h2>
        </v-card-title>
        <v-card-text>
          <div>
            <label>New Password</label>
            <v-text-field
              v-model="changePasswordFormData.password"
              :rules="[
                isRequired(true, isNotEmpty, {
                  req: 'New Password Is Required',
                  error: 'New Password Is Required',
                }),
                (v) => !shouldValidatePassword || asyncPasswordResponse.data.valid || asyncPasswordResponse.data.message
              ]"
              flat
              solo
              type="password"
              name="New Password"
            />

            <label>Confirm Password</label>
            <v-text-field
              v-model="changePasswordFormData.confirmPassword"
              :rules="[
                isRequired(true, isNotEmpty, {
                  req: 'Passwords Must Match',
                  error: 'Passwords Must Match',
                }),
                shouldMatch(changePasswordFormData, 'password'),
              ]"
              flat
              solo
              type="password"
              name="Confirm Password"
            />
          </div>
        </v-card-text>

        <v-card-actions>
          <v-layout justify-end>
            <div class="button-cont">
              <div style="margin-right: -1.5%">
                <v-btn
                  id="close-modal-button"
                  color="grey darken"
                  outline
                  @click="close"
                >
                  Cancel
                </v-btn>
                <v-btn id="save-button" color="primary" @click="save">
                  Change Password
                </v-btn>
              </div>
            </div>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { authComputed } from '@/state/helpers'
import { isNotEmpty, isRequired, shouldMatch } from '@/utils/validators'
import authService from '@/services/auth'

export const AUTH0_PASSWORD_ERROR_MESSAGE =
  'User does not have an Auth0 ID for username/password sign-in.'

export default {
  props: {
    userId: { type: String, default: null },
    isAdmin: { type: Boolean, default: false },
  },
  data() {
    return {
      changePasswordFormData: {
        password: '',
        confirmPassword: '',
      },
      asyncPasswordResponse: {
        data: {
          valid: false,
          message: 'Something went wrong'
        }
      },
      shouldValidatePassword: false
    }
  },
  computed: {
    ...authComputed,
  },
  methods: {
    isNotEmpty,
    isRequired,
    shouldMatch,
    async save() {
      this.asyncPasswordResponse = await authService.validatePassword(this.changePasswordFormData.password)
      this.shouldValidatePassword = true

      const formIsValid = this.$refs.changePasswordModal.validate()
      if (!formIsValid) {
        return
      }

      if (!this.isAdmin) {
        return
      }

      await this.$store
        .dispatch('auth/adminChangePassword', {
          payload: {
            userId: this.userId,
            newPassword: this.changePasswordFormData.password,
          },
        })
        .catch((e) => {
          if (e.response.data.message === AUTH0_PASSWORD_ERROR_MESSAGE
          ) {
            this.$store.dispatch('app/showAlert', {
              message:
                'This user has not authenticated with Auth0, and therefore cannot update their password. Please have this user logout and log back in.',
              type: 'error',
            })
          } else {
            this.$store.dispatch('app/showAlert', {
              message: 'Unable to change user password.',
              type: 'error',
            })
          }
        })

      this.close()
      this.$store.dispatch('app/showAlert', {
        message: 'Successfully changed password.',
      })
    },
    close() {
      this.$store.dispatch('app/closeDialog')
    },
  },
}
</script>
<style lang="scss" scoped>
.change-password-form {
  width: 520px;

  &.change-password-form-small {
    width: auto;
  }
}

.dialog-container {
  display: flex;
  flex-direction: column;
  width: 600px;
  height: 500px;
  padding: 4% 8% 4% 8%;
  overflow-y: hidden;
  border-radius: 5px;

  .label-cont {
    display: flex;
    justify-content: space-between;
  }

  .button-cont {
    display: flex;
    justify-content: flex-end;
  }

  label {
    margin: 0;
    font-size: 20px;
    line-height: 1.42857;
  }

  .close {
    float: right;
    padding: 0;
    font-size: 21px;
    font-weight: 700;
    line-height: 1;
    color: $gray-base;
    text-shadow: 0 1px 0 $white;
    cursor: pointer;
    background: transparent;
    border: 0;
    opacity: 0.2;
  }

  .close:hover {
    opacity: 0.4;
  }
}

.description-input {
  height: 100%;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: $blue-light;
}

.delete-target {
  color: $white;
  background-color: $red !important;
}
</style>
